import { createContext, useContext, useState, useEffect } from 'react';
import { useRouter } from 'next/router';


const UserSearchContext = createContext();

export const UserSearchProvider = ({ children }) => {
    const router = useRouter();
    const [searchQuery, setSearchQuery] = useState("");
    const [users, setUsers] = useState([]);
    const [showResults, setShowResults] = useState(false);
    const [emptyResults, setEmptyResults] = useState(false);
    const [inputValue, setInputValue] = useState("");

    // Clear context and storage if accessed directly through a fresh page load
    useEffect(() => {
        const isNavigatedInApp = window.sessionStorage.getItem('navigatedInApp');

        if (!isNavigatedInApp) {
            clearUserSearch();
        }

        window.sessionStorage.setItem('navigatedInApp', 'true');
    }, [router.pathname]);


    useEffect(() => {
        const savedSearchQuery = window.localStorage.getItem('searchQuery');
        const savedUsers = window.localStorage.getItem('users');
        const savedShowResults = window.localStorage.getItem('showResults');
        const savedInputValue = window.localStorage.getItem('inputValue');

        if (savedSearchQuery) setSearchQuery(savedSearchQuery);
        if (savedUsers) setUsers(JSON.parse(savedUsers));
        if (savedShowResults) setShowResults(JSON.parse(savedShowResults));
        if (savedInputValue) setInputValue(savedInputValue);
    }, []);


    useEffect(() => {
        window.localStorage.setItem('searchQuery', searchQuery);
        window.localStorage.setItem('users', JSON.stringify(users));
        window.localStorage.setItem('showResults', JSON.stringify(showResults));
        window.localStorage.setItem('inputValue', inputValue);
    }, [searchQuery, users, showResults, inputValue]);

    const clearUserSearch = () => {
        setSearchQuery("");
        setUsers([]);
        setShowResults(false);
        setEmptyResults(false);
        setInputValue("");
        window.localStorage.removeItem('searchQuery');
        window.localStorage.removeItem('users');
        window.localStorage.removeItem('showResults');
        window.localStorage.removeItem('inputValue');
    };


    return (
        <UserSearchContext.Provider
            value={{
                searchQuery,
                setSearchQuery,
                users,
                setUsers,
                showResults,
                setShowResults,
                emptyResults,
                setEmptyResults,
                inputValue,
                setInputValue,
                clearUserSearch,
            }}
        >
            {children}
        </UserSearchContext.Provider>
    );
};

export const useUserSearchContext = () => useContext(UserSearchContext);
